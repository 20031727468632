import AnimatedCursor from "react-animated-cursor";
import "./App.css";
import Home from "./Components/Home";
import Header from "./Components/Header";
import About from "./Components/About";
import Resume from "./Components/Resume";
import Portfolio from "./Components/Portfolio";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";
import "animate.css";
import Skills from "./Components/Skills";
import StickyCursor from "./Components/StickyCursor";
import Lenis from 'lenis'

function App() {
   // Initialize Lenis
   const lenis = new Lenis();

   // Listen for the scroll event and log the event data
   lenis.on('scroll', (e) => {
     console.log(e);
   });
 
   // Use requestAnimationFrame to continuously update the scroll
   function raf(time) {
     lenis.raf(time);
     requestAnimationFrame(raf);
   }
 
   requestAnimationFrame(raf);
  return (
    <>
      <div>
        {/* <AnimatedCursor
          innerSize={8}
          outerSize={8}
          color="255, 193, 7"
          outerAlpha={0.2}
          innerScale={0.7}
          outerScale={5}
          clickables={[
            "a",
            'input[type="text"]',
            'input[type="email"]',
            'input[type="number"]',
            'input[type="submit"]',
            'input[type="image"]',
            "label[for]",
            "select",
            "textarea",
            "button",
            ".link",
          ]}
        /> */}
<StickyCursor/>
        <Header />
        <Home id="home" title="Home" />
        <About id="about" title="About" />
        <Skills />
        <Resume id="resume" title="Resume" />
        <Portfolio id="portfolio" title="Portfolio" />
        <Contact id="contact" title="Contact" />
        <Footer />
      </div>
    </>
  );
}

export default App;
