import React from "react";
import dnd from "../img/image.png";
import green from "../img/green.png";
import job from "../img/job.png";
import ats from "../img/ats.png";
import aarish from "../img/aarish.png";
import tasmia from "../img/tasmia.png";

const Portfolio = ({ id, title }) => {
  return (
    <div className="bg_light_blue" id={id}>
      <div className="pt-24">
        <div className="heading">
          <h4 className="main_heading">{title}</h4>
          <h2 className="sub_heading hidden sm:block">PORTFOLIO</h2>
        </div>
      </div>

      <div className="px-4  md:px-8 lg:px-16 xl:px-32 pt-10 pb-24 portfolio flex justify-center">
        <a href="#" className="box" data-aos="slide-up">
          <div className="box_hover animate__fadeIn animate__animated">
            <a href="https://hirecruise.us/" target="_blank">
              <i class="fa-solid fa-link"></i>
            </a>
          </div>

          <img src={ats} alt="ats" className="w-[100%] h-[100%]" />
        </a>

        <a href="#" className="box" data-aos="slide-up">
          <div className="box_hover animate__fadeIn animate__animated">
            <a href="https://aarish-1.netlify.app/" target="_blank">
              <i class="fa-solid fa-link"></i>
            </a>
          </div>

          <img src={aarish} alt="aarish" className="w-[100%] h-[100%]" />
        </a>

        <a href="#" className="box" data-aos="slide-up">
          <div className="box_hover animate__fadeIn animate__animated">
            <a href="https://tasmiya-iota.vercel.app/" target="_blank">
              <i class="fa-solid fa-link"></i>
            </a>
          </div>

          <img src={tasmia} alt="tasmia" className="w-[100%] h-[100%]" />
        </a>

        <a href="#" className="box" data-aos="slide-up">
          <div className="box_hover animate__fadeIn animate__animated">
            <a href="https://life-style-six.vercel.app/" target="_blank">
              <i class="fa-solid fa-link"></i>
            </a>
          </div>

          <img src={dnd} alt="dnd" className="w-[100%] h-[100%]" />
        </a>

        <a href="#" className="box" data-aos="slide-up">
          <div className="box_hover animate__fadeIn animate__animated">
          
            <a href="https://green900.netlify.app/" target="_blank">
              <i class="fa-solid fa-link"></i>
            </a>
          </div>

          <img src={green} alt="dnd" className="w-[100%] h-[100%]" />
        </a>

        <a href="#" className="box" data-aos="slide-up">
          <div className="box_hover animate__fadeIn animate__animated">
         
            <a href="https://mui-single-page.netlify.app/" target="_blank">
              <i class="fa-solid fa-link"></i>
            </a>
          </div>

          <img src={job} alt="dnd" className="w-[100%] h-[100%]" />
        </a>
      </div>
    </div>
  );
};

export default Portfolio;
